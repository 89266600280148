export const mapHighlights = [
  {
    name: "Search bar",
    markdown: `Use the search bar to recenter the map on a location or quickly navigate to a specific property, company, person, or task.
    `,
  },
  {
    name: "Timeline",
    markdown: `Use the timeline to see recent activity within the map boundaries. You can also time travel to see what points of interest existed at different moments in time.
    `,
  },
  {
    name: "POIs",
    markdown: `The map displays different markers for properties, land coverings, land parcels, and other active users. Tower Hunt properties act as containers for property rights (ex. fee interests, leaseholds) and land coverings (ex. buildings, parking lots). Markers link to their respective property diagrams, where you can explore details.
    `,
  },
  {
    name: "Explored areas",
    markdown: `The first time you visit Tower Hunt, the map is totally covered by a darkened layer and no markers appear. To reveal data, you must explore the map at the local level by zooming in. When you do this, bright openings appear. These {% inlineRouterLink articleId="explored-areas" %}explored areas{% /inlineRouterLink %} teach Tower Hunt which locations matter to you, triggering various personalized features.
    `,
  },
];

export const datapointHighlights = [
  {
    name: "Datapoint",
    markdown: `Datapoints are styled to inform you at a glance. If you have access to the underlying data, you'll see it here. Color and icons indicate who can access the data, among other important statuses. Clicking a datapoint displays a menu of actions and contextual info.
    `,
  },
  {
    name: "Pricing",
    markdown: `Certain datapoints are freely accessible. Others must be licensed. Every datapoint displays information about its pricing and quality. Unlocking the data you need is done via a swipe.
    `,
  },
  {
    name: "Safezone",
    markdown: `In Tower Hunt, the term "Safezone" means private, and "private" means you are in charge of who has access. Certain datapoints live in your Safezone, but you can also attach private notes, files, and tasks to any datapoint.
    `,
  },
  {
    name: "Editing",
    markdown: `Datapoints can be edited or removed in real-time, so you're never stuck with someone else's bad or incomplete data. Editing public datapoints triggers a validation process &mdash; determining whether the data is eligible for revenue-sharing &mdash; but your changes are immediately available to you.
    `,
  },
];

export const sidebarHighlights = [
  {
    name: "Analyze",
    markdown: `The analyze tab works with collections of datapoints, helping to summarize and organize that data. If you're looking for a calculated output, such as the total size of a collection of properties, head here.
    `,
  },
  {
    name: "Me",
    markdown: `As the name suggests, you are the focus of the Me tab. Here, you'll find personalized news, your tasks, your Safezone, and your contributions to the public database.
    `,
  },
  {
    name: "Contacts",
    markdown: `People and companies move real estate forward. In Tower Hunt, you can extend your private database and get more done with teams. Depending upon your main view, the contacts tab may also help you identify important market players at a given location or property.
    `,
  },
  {
    name: "Situational tabs",
    markdown: `Other important activities are handled by the sidebar from time to time. These include exploring deals, editing datapoints, validating crowdsourced data, managing your account, getting help, and resetting your password.
    `,
  },
];

export const quickActionsHighlights = [
  {
    name: "Unread news",
    markdown: `Personalized news and notifications may be pushed to you throughout the day. A pulsing Tower Hunt logo and "swipe for news" slider indicates you have new items to read when you're ready.
    `,
  },
  {
    name: "Crowdsourcing",
    markdown: `Quickly check your current {% inlineAppIcon iconName="star" %}**reputation**{% /inlineAppIcon %}, {% inlineAppIcon iconName="dollar" %}**balance**{% /inlineAppIcon %}, {% inlineAppIcon iconName="shieldCheck" %}**available validations**{% /inlineAppIcon %}, and {% inlineAppIcon iconName="shieldExclamation" %}**pending contributions**{% /inlineAppIcon %}. Metrics update in real-time to keep you close to the flow of data on Tower Hunt. Click any metric to view and manage its related activites.
    `,
  },
  {
    name: "Help",
    markdown: `Click the {% inlineAppIcon iconName="questionMark" %}**question mark icon**{% /inlineAppIcon %} to access help and learning resources.
    `,
  },
  {
    name: "User menu",
    markdown: `Click your initials in the upper-right to access your profile and preferences.
    `,
  },
];

export const howToUseTowerHuntQuickStart = {
  order: 1,
  name: "How to use Tower Hunt: your quick start guide",
  subcategory: "Intro to Tower Hunt",
  markdown: `# How to use Tower Hunt: your quick start guide

  Welcome to Tower Hunt &mdash; a commercial real estate database. Tower Hunt helps users add value any day in any market by unifying key datapoints.

  To get started, check out the guide below for a quick tour.

  {% callout type="tip" %}
  **Tip:** When you're ready for more, see our {% inlineRouterLink %}Tower Hunt 101{% /inlineRouterLink %} and {% inlineRouterLink %}video tutorials{% /inlineRouterLink %}.
  {% /callout %}

  ## Map

  The map is your primary tool for honing in on the data you need. Importantly, the map is also how you declare which markets you care about, personalizing your Tower Hunt experience. Depending upon your zoom level, you'll see the search bar, a timeline, various points of interest (POIs), and areas you've explored.

  ![Map screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  {% tabbedContainer tabs=$mapHighlights marginStyles="mb-4" /%}

  {% callout type="tip" %}
  **Tip:** Press {% inlineKeyboardShortcut %}/{% /inlineKeyboardShortcut %} to focus the search bar.
  {% /callout %}

  ## Datapoints

  Datapoints are fundamental to working in Tower Hunt &mdash; they facilitate access to the real estate information you need. Datapoints enable other key benefits such as revenue sharing, data privacy, and real-time editing.

  ![Datapoint screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  {% tabbedContainer tabs=$datapointHighlights /%}

  ## Sidebar

  The sidebar displays a variety of information intended to support your main view. You'll see horizontal and vertical tabs for navigation. The sidebar can be resized, relocated or even hidden to suit your needs.

  ![Sidebar screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  {% tabbedContainer tabs=$sidebarHighlights /%}

  ## Quick Actions

  Use this cluster of metrics and controls to stay informed of news, crowdsourcing activities, and earning/spending. You can also get help with Tower Hunt and manage your account from here.

  ![Quick actions screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  {% tabbedContainer tabs=$quickActionsHighlights /%}`,
};
